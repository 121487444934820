import React from 'react'
import './OrganisationSection.css'
import rightImg from '../../../assets/images/Membership/MembershipPage.png'
import leftImg from '../../../assets/images/Membership/Rectangle2.png'

const organisationList1 = [
    "Funding Calendar: Join our funding calendar for 2025 from the EU Commission, Nordic Grants, and beyond.",
    "Professional Support: Receive your designated professional relationship manager to navigate grants and match-make with partners for ongoing applications or building consortiums and projects based on your value propositions.",
    "Ukraine Funding: Access large funding for Ukraine, participate in projects for Ukraine, and partner with organizations active in/for Ukraine.​",
    "ENFA AI Tool: Use ENFA’s AI tool for call and consortium match-making, proposal writing, and development.",
    // "Event Discounts: Get a 15% discount on all ENFA regular events in 2024, including capacity building and training, networking, and knowledge sharing.​",
    "Content Access: Get free access to world-class, up-to-date content on research and technology, global events, business deals and support, and a pool of proposals and partners.​",
    "Advocacy: Share your insights and join our united voice towards authorities such as the EU Commission, UN, and EU Parliament to suggest needed changes."
]

const OrganisationSection = () => {
    return (
        <section className="Organisation-section">
            <div className="container">
                <div className="h-100 container mx-auto">
                    <div className="d-flex align-items-center justify-content-center flex-column h-100">
                        <h3 className='membership'>
                            OFFERS
                        </h3>
                        <h2 className=" text-center mx-auto OrganisationSectionHeading">
                            TO BECOME A MEMBER
                        </h2>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                            <p className="heading-bg"><strong>As a member, you get access to exclusive funding opportunities, networking, proposal development support, and more.</strong></p>
                            <ul className="ulList">
                                {organisationList1?.map((item, index) => (
                                    <li key={index} className='listItem'>{item}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                            <img loading='lazy' className='right-img' src={rightImg} width={"100%"}/>
                            <div className='' style={{width:'100%'}}>
                            <a href="https://calendly.com/nilu-enfa/30min" class="d-block text-decoration-none text-center btnRegister btn-register " style={{backgroundColor:"#2248ac", color: 'white', width:'100%'}} target='_blank'>Book Your Appointment With The Secretary General</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default OrganisationSection