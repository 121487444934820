import React from 'react'
import Summit24AboutSection from './Summit24AboutSection/Summit24AboutSection'
import Summit24Sec2 from './Summit24Section2/Summit24Sec2'
import SummitSpeaker from './SummitSpeaker/SummitSpeaker'
import TicketPricesSection from './TicketPricesSection/TicketPricesSection'
import ProgramDetails from './ProgramDetails/ProgramDetails'
import AboutCitySection from './AboutCity/AboutCity'
import Summit24Speakers from './Summit24Speakers/Summit24Speakers'
import SummitSlider24 from './Slider/SummitSlider24'

const Summit2024 = () => {
  return (
<div className='bgColor'>     
   <Summit24AboutSection />
      <Summit24Sec2 />
      {/* <ProgramDetails />
      <TicketPricesSection /> */}
      <Summit24Speakers />
      {/* <AboutCitySection /> */}
      <SummitSpeaker />
      <SummitSlider24/>
      
      </div>
  )
}

export default Summit2024