import React from 'react'
import './Summit24Sec2.css'
import emp_big from '../../../assets/images/Summit2023/Summit23_1_1.JPEG'
import emp_small from '../../../assets/images/Summit2023/summit23_small.JPEG'
import emp_big1 from '../../../assets/images/summit2024/IDF.png'

import image1 from '../../../assets/images/summit2024/image1.JPG'
import image3 from '../../../assets/images/summit2024/image2.JPG'
import image11 from '../../../assets/images/summit2024/image11.JPG'
import image12 from '../../../assets/images/summit2024/image12.JPG'

const Summit24Sec2 = () => {
  return (
    <>
    <section className="summit2023AboutSection">
        <div className="summit2023AboutSectionBG">
          <div className="container">
            <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div className="EmoImgBigSmallSummit23">
                  <div className="bigSummit23"><img loading='lazy' src={image3} /></div>
                  <div className="smallSummit23"><img loading='lazy' src={image1} /></div>
                  {/* <div className="smallSummit23"><img loading='lazy' src={image3} /></div> */}

                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-6">
                <div className="summit2023EnfaBox">
               
                  <div className="titleEmpoweringSummit23">
                  ENFA BUSINESS <br></br> SUMMIT 2024
                  </div>
                  <div className="text-uppercase textEnfaSummit23">Fostering Collaboration <br></br>Across Europe and the Nordics</div>
                  <div className="d-flex EmpoweringBlockSummit23">
                    <div className="lineVSummit23 mt-3"></div>
                    <div className="praEmpowerDivSummit23 py-2">
                      <p className='para1'>
                      The Summit in Stockholm was one of the most diverse gatherings of its kind, uniting a broad spectrum of participants, including SMEs, NGOs, embassies, grant specialists, EU Commission leaders, municipalities, research institutions, and more.</p>
                      <p className='para1'>Throughout the event, all of the participants had the opportunity to take part in facilitated dynamic workshops on various topics, which encouraged the development of new concepts and partnerships by leveraging the unique synergies of the diverse attendees.</p>
                      <p className='para1'>With insightful presentations and panel discussions led by leaders and stakeholders from Europe, the Nordics, and Africa, the summit fostered cross-sector collaboration, sparking ideas and initiatives that promise to drive future growth and innovation within the ENFA community.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
    <section className="d-flex homeENFASectionSummit2023 container" style={{background:'transparent'}}>
      
      <div className="leftSide" style={{marginLeft:'0'}}><img loading='lazy' src={emp_big1} className='imgLeft' /></div>
      <div className="rightSide">
        <span className='osla-business'>ENFA & Innovation Development Fund (IDF)</span>
        <p>During the first day of the summit, a cooperation agreement between ENFA and the Innovation Development Fund (IDF) of Ukraine was solemnly signed. This partnership underscores our commitment to supporting the growth of small businesses in Ukraine, which now, more than ever, requires dedicated investment and support.</p>
      </div>
    </section>
    <section className="summit2023AboutSection">
        <div className="summit2023AboutSectionBG">
          <div className="container">
            <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div className="EmoImgBigSmallSummit23">
                  <div className="bigSummit23"><img loading='lazy' src={image11} /></div>
                  <div className="smallSummit23"><img loading='lazy' src={image12} /></div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-6">
                <div className="summit2023EnfaBox">
                  <div className="titleEmpoweringSummit23">
                  The summit culminated in a sophisticated gala dinner
                  </div>
                  {/* <div className="text-uppercase textEnfaSummit23">The summit culminated in <br></br>a sophisticated gala dinner</div> */}
                  <div className="d-flex EmpoweringBlockSummit23">
                    <div className="lineVSummit23 mt-3"></div>
                    <div className="praEmpowerDivSummit23 py-2">
                      <p className='para1'>
                      The summit culminated in a sophisticated gala dinner, providing an evening of refined dining, engaging conversations, and celebratory moments. Set against the backdrop of Berns's vibrant atmosphere, with the amazing music by xxx, the event offered participants a chance to unwind, deepen connections, and reflect on the day's insights.That was, for sure, a memorable highlight of the ENFA Business Summit.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
    </>
  )
}

export default Summit24Sec2