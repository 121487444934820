import React from 'react'
import './Summit24Speakers.css'

import img1 from '../../../assets/images/summit2024/speakers/summit24speaker1.jpeg';
import img2 from '../../../assets/images/summit2024/speakers/summit24speaker2.jpeg';
import img3 from '../../../assets/images/summit2024/speakers/summit24speaker3.jpeg';
import img4 from '../../../assets/images/summit2024/speakers/summit24speaker4.jpeg';
import img5 from '../../../assets/images/summit2024/speakers/summit24speaker5.png';
import img6 from '../../../assets/images/summit2024/speakers/summit24speaker6.png';
import img7 from '../../../assets/images/summit2024/speakers/summit24speaker7.jpeg';
import img8 from '../../../assets/images/summit2024/speakers/summit24speaker8.jpeg';
import img9 from '../../../assets/images/summit2024/speakers/summit24speaker9.jpg';
import img10 from '../../../assets/images/summit2024/speakers/summit24speaker10.jpeg';
import img11 from '../../../assets/images/summit2024/speakers/summit24speaker11.jpeg';
import img12 from '../../../assets/images/summit2024/speakers/summit24speaker12.jpeg';
import img13 from '../../../assets/images/summit2024/speakers/summit24speaker13.jpeg';
import img14 from '../../../assets/images/summit2024/speakers/summit24speaker14.jpeg';
import img15 from '../../../assets/images/summit2024/speakers/summit24speaker15.jpg';
import img16 from '../../../assets/images/summit2024/speakers/summit24speaker16.png';
import img17 from '../../../assets/images/summit2024/speakers/summit24speaker17.jpg';
import img18 from '../../../assets/images/summit2024/speakers/summit24speaker18.png';
import img19 from '../../../assets/images/summit2024/speakers/summit24speaker19.jpg';
import img20 from '../../../assets/images/summit2024/speakers/summit24speaker20.jpeg';
import img21 from '../../../assets/images/summit2024/speakers/summit24speaker21.jpeg';
import img22 from '../../../assets/images/summit2024/speakers/summit24speaker22.jpeg';
import img23 from '../../../assets/images/summit2024/speakers/summit24speaker23.jpeg';
import img24 from '../../../assets/images/summit2024/speakers/summit24speaker24.png';
import img25 from '../../../assets/images/summit2024/speakers/summit24speaker25.jpeg';
import img26 from '../../../assets/images/summit2024/speakers/summit24speaker26.jpeg';
import img27 from '../../../assets/images/summit2024/speakers/LisaSummit24Speakers.jpeg'

import speakerTrangle from '../../../assets/images/speaker-trangle.svg'
import { SpeakerCard } from '../../Summit2023/Speaker/SummitSpeaker23'
import Section3 from "../../../assets/images/AboutCity/Groupedited.png";

const speakersData = [
  {
    imgSrc: img1,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Amina Touray',
    description: 'Director of Gambia Tourism Boar'
  },
  {
    imgSrc: img2,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Andréa Råsberg',
    description: 'Country Manager, Reach For Change'
  },
  {
    imgSrc: img3,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Dr. Morten Kieldal',
    description: 'Surgeon at Uppsala University Hospital and advisor to MSF and Operation Smile'
  },
  {
    imgSrc: img4,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Erik Krüger',
    description: 'Deputy Director, Stockholm Business Region'
  },
  {
    imgSrc: img5,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Fabrizio Zucca',
    description: 'Business Development Advisor, EU (CINEA) and EBRD'
  },
  {
    imgSrc: img6,
    imgSpeakerTraingle: speakerTrangle,
    name: 'H.E. Sheikh Al-moustapha Kouyateh',
    description: 'Ambassador At-Large, Ministry of State for Presidential Affairs, Republic of Liberia'
  },
  {
    imgSrc: img7,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Hamid Rahnama',
    description: 'Business Development Executive, Avanade - Accenture Microsoft Business Group'
  },
  {
    imgSrc: img8,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Johan Attby',
    description: 'General Partner, Norrsken Funden & VC'
  },
  {
    imgSrc: img9,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Johanna Brismar Skoog',
    description: 'Ambassador Swedish MSF (Ministry for Foreign Affairs)'
  },
  // {
  //   imgSrc: img10,
  //   imgSpeakerTraingle: speakerTrangle,
  //   name: 'Linus Kaasik',
  //   description: 'Co-founder and Head of Commerce, Nordic Tech House, Epicenter (tbc)'
  // },
  {
    imgSrc: img27,
    imgSpeakerTraingle: speakerTrangle,
    name: ' Lisa Westerdahl',
    description: 'Chief People and Sustainability Officer and Head of Corporate Comms Karo Healthcare'
  },
  // {
  //   imgSrc: img11,
  //   imgSpeakerTraingle: speakerTrangle,
  //   name: 'MacDella MacKie Cooper',
  //   description: 'Senior Political Advisor to the President of Republic of Liberia'
  // },
  // {
  //   imgSrc: img12,
  //   imgSpeakerTraingle: speakerTrangle,
  //   name: 'Mariia Tintul',
  //   description: 'Head of ENFA Ukraine'
  // },
  {
    imgSrc: img13,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Mathias Hamberg',
    description: 'Senior Research Officer, Swedish Research Council'
  },
  {
    imgSrc: img14,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Maya Jakobsson',
    description: 'Chief Development Officer, Operation Smile Sweden'
  },
  {
    imgSrc: img15,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Mehreteab Mulugeta',
    description: 'Ambassador of Ethiopia to the Nordics'
  },
  {
    imgSrc: img16,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Michaela Berglund',
    description: 'Founder and CEO, Feminvest'
  },
  {
    imgSrc: img17,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Mikkel Bendixsen',
    description: 'Head of Events, TechBBQ'
  },
  {
    imgSrc: img18,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Natalia Kushnerska',
    description: 'Acting Director, Ukrainian Innovation Development Fund'
  },
  {
    imgSrc: img19,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Niloufar Gharavi',
    description: 'Founder & Secretary General of ENFA'
  },
  // {
  //   imgSrc: img20,
  //   imgSpeakerTraingle: speakerTrangle,
  //   name: 'Oleg Missikoff',
  //   description: 'Co-founder of Earth 3.0 Foundation'
  // },
  // {
  //   imgSrc: img21,
  //   imgSpeakerTraingle: speakerTrangle,
  //   name: 'Patrick Kato',
  //   description: 'Country Director ENFA Sweden'
  // },
  {
    imgSrc: img22,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Rune Kongshaug',
    description: 'Co-founder, United Village Global'
  },
  {
    imgSrc: img23,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Sarah Ouakim',
    description: 'Open Innovation Market Lead, Accenture Nordics'
  },
  {
    imgSrc: img25,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Siduri Poli',
    description: 'Chief Growth Officer & IR, Nordic Angels'
  },
  {
    imgSrc: img26,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Stefano Matonte',
    description: 'UN Global Compact and EU Policy'
  }
];
const Summit24Speakers = () => {
  return (
    <>
    <section className="position-relative ">
    <div className="Summit24SpeakersBg">
        <div className="container">
        <div className="d-flex align-items-center justify-content-center flex-column h-100">
         <h2 className="text-center mx-auto AboutCitySectionHeading">Our Speakers</h2>
            </div>
            <div className="row">
                {speakersData.map((speaker, index) => (
                    <div key={index} className="col-6 col-lg-3 col-xl-3">
                        <SpeakerCard {...speaker} />
                    </div>
                ))}
                
            </div>
        </div>
    </div>
</section>
{/* <section className="AboutCity-section">
<div className="container">
        <div className="h-100 container mx-auto">
          <div className="d-flex align-items-center justify-content-center flex-column h-100">
            <h2 className="text-center mx-auto AboutCitySectionHeading">Getting around Stockholm</h2>
            <p className="thanks-to-stockholm">
              Thanks to Stockholm’s safe, punctual and efficient public transport system, you can quickly travel between different locations. In compact and cozy Stockholm, everything is right on your doorstep.
            </p>

            <div className="Getting-around-StockholmCard">
              <div className="overflow-hidden Getting-around-image">
                <img loading='lazy' alt="" src={Section3} />
                <div className="label">
                  <h3 className="labelPublic">Public transportation in Stockholm</h3>
                  <p className="paragraphAboutcity">By subway, tram, bus, ferry, or commuter train. Stockholm's local transportation network takes you almost anywhere.</p></div>
              </div>
            </div>
            </div></div></div>
            </section> */}
            </>
  )
}

export default Summit24Speakers