import React, { useState } from 'react'
import partner1 from './../../../assets/images/about/partners/partner1.png'
import partner2 from './../../../assets/images/about/partners/partner2.png'
import partner3 from './../../../assets/images/about/partners/partner3.png'
import partner4 from './../../../assets/images/about/partners/partner4.png'
import partner5 from './../../../assets/images/about/partners/partner5.png'
import partner6 from './../../../assets/images/about/partners/partner6.png'
import partner7 from './../../../assets/images/about/partners/partner7.png'
import partner8 from './../../../assets/images/about/partners/partner8.png'
import partner9 from './../../../assets/images/about/partners/partner9.png'
import partner10 from './../../../assets/images/about/partners/partner10.png'
import partner11 from './../../../assets/images/about/partners/partner11.png'
import partner12 from './../../../assets/images/about/partners/partner12.png'
import partner13 from './../../../assets/images/about/partners/partner13.png'
import partner14 from './../../../assets/images/about/partners/partner14.png'
import partner15 from './../../../assets/images/about/partners/partner15.png'
import partner16 from './../../../assets/images/about/partners/partner16.png'
import partner17 from './../../../assets/images/about/partners/partner17.png'
import partner18 from './../../../assets/images/about/partners/partner18.png'
import partner19 from './../../../assets/images/about/partners/partner19.png'
import partner20 from './../../../assets/images/about/partners/partner20.png'
import partner21 from './../../../assets/images/about/partners/partner21.png'
//import partner22 from './../../../assets/images/about/partners/partner22.png'
import partner23 from './../../../assets/images/about/partners/partner23.png'
import partner24 from './../../../assets/images/about/partners/partner24.png'
import partner25 from './../../../assets/images/about/partners/partner25.png'
import partner26 from './../../../assets/images/about/partners/partner26.png'
import partner27 from './../../../assets/images/about/partners/partner27.png'
import partner28 from './../../../assets/images/about/partners/partner28.png'
import partner29 from './../../../assets/images/about/partners/partner29.png'
import partner30 from './../../../assets/images/about/partners/partner30.png'
import partner31 from './../../../assets/images/about/partners/partner31.png'
import partner32 from './../../../assets/images/about/partners/partner32.png'
import partner33 from './../../../assets/images/about/partners/partner33.png'
import partner34 from './../../../assets/images/about/partners/partner34.png'
import partner35 from './../../../assets/images/about/partners/partner35.png'
import partner36 from './../../../assets/images/about/partners/partner36.png'
import partner37 from './../../../assets/images/about/partners/partner37.png'
import partner38 from './../../../assets/images/about/partners/partner38.png'
import partner39 from './../../../assets/images/about/partners/partner39.png'
import partner40 from './../../../assets/images/about/partners/partner40.png'
import partner41 from './../../../assets/images/about/partners/partner41.png'
import partner42 from './../../../assets/images/about/partners/partner42.png'
import partner43 from './../../../assets/images/about/partners/partner43.png'
import partner44 from './../../../assets/images/about/partners/partner44.png'

// import partner17 from './../../../assets/images/about/partners/shippiing.png'
// import partner18 from './../../../assets/images/about/partners/smb.png'
// import partner19 from './../../../assets/images/about/partners/thap.png'
//import partner from './../../../assets/images/about/partners/partner.png'

import './Partner.css'
import RegistrationForm from '../../Registration/RegistrationForm/RegistrationForm'
import MembershipForm from '../../Membership/MembershipForm/MembershipForm'



export const PartnerComponent = () => {
    const [isPopupOpen, setPopupOpen] = useState(false);
    const openPopup = () => {
        document.body.style.overflow = 'hidden'; 
        setPopupOpen(true);
      };
      const closePopup = () => {
        document.body.style.overflow = ''; 
        setPopupOpen(false);
      };
    return (
        <div className=" d-flex align-items-center justify-content-center partnerbannerText" >
            <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="row">
                    <div className="aboutMissionPartner" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <div className="text-uppercase textOur">Our</div>
                        <div className="text-uppercase textMisson">Alliance Partners </div>
                        <div className="text-uppercase textPartnerToBe">+ALLIANCE PARTNER-To-Be </div>
                    </div>
                    <div>
                       
                    {/* <div className="container" style={{textAlign:'center',marginTop:'20px',marginBottom:'40px'}}>
                        <div className="row">
                            <div className="col-lg-2 col-sm-3">
                            <div className="overflow-hidden img">
                                    <img loading='lazy' src={partner1} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-2 col-sm-3 ">
                            <div className="overflow-hidden img">
                                    <img loading='lazy' src={partner1} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-2 col-sm-3">
                            <div className="overflow-hidden img">
                                    <img loading='lazy' src={partner1} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-2 col-sm-3">
                            <div className="overflow-hidden img">
                                    <img loading='lazy' src={partner1} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-2 col-sm-3">
                            <div className="overflow-hidden img">
                                    <img loading='lazy' src={partner1} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-2 col-sm-3">
                            <div className="overflow-hidden img">
                                    <img loading='lazy' src={partner1} alt="" />
                                </div>
                            </div>
                        </div>
                        </div>

                        <div className="container" style={{textAlign:'center',marginTop:'20px',marginBottom:'40px'}}>
                        <div className="row">
                            <div className="col-lg-2 col-sm-3">
                            <div className="overflow-hidden img">
                                    <img loading='lazy' src={partner1} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-2 col-sm-3">
                            <div className="overflow-hidden img">
                                    <img loading='lazy' src={partner1} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-2 col-sm-3">
                            <div className="overflow-hidden img">
                                    <img loading='lazy' src={partner1} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-2 col-sm-3">
                            <div className="overflow-hidden img">
                                    <img loading='lazy' src={partner1} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-2 col-sm-3">
                            <div className="overflow-hidden img">
                                    <img loading='lazy' src={partner1} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-2 col-sm-3">
                            <div className="overflow-hidden img">
                                    <img loading='lazy' src={partner1} alt="" />
                                </div>
                            </div>
                        </div>
                        </div> */}

                        <div className="container" style={{ textAlign: 'center'}}>
                            <div className="parent">
                                <div className="child">
                                    <div className="overflow-hidden img">
                                    <img loading='lazy' src={partner1} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner2} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner3} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner4} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner5} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner6} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner7} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner8} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container" style={{ textAlign: 'center'}}>
                            <div className="parent">
                                <div className="child">
                                    <div className="overflow-hidden img">
                                    <img loading='lazy' src={partner9} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner10} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner11} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner12} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner13} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner14} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner15} alt="" />
                                    </div>
                                </div>
                                {/* <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner43} alt="" />
                                    </div>
                                </div> */}
                            </div>
                        </div>

                        <div className="container" style={{ textAlign: 'center'}}>
                            <div className="parent">
                                <div className="child">
                                    <div className="overflow-hidden img">
                                    <img loading='lazy' src={partner17} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner18} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner19} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner20} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner21} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner23} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner24} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner25} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container" style={{ textAlign: 'center'}}>
                            <div className="parent">
                                <div className="child">
                                    <div className="overflow-hidden img">
                                    <img loading='lazy' src={partner26} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner27} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner28} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner29} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner30} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner31} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner32} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner33} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container" style={{ textAlign: 'center'}}>
                            <div className="parent">
                                <div className="child">
                                    <div className="overflow-hidden img">
                                    <img loading='lazy' src={partner34} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner35} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner36} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner37} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner38} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner39} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner40} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner41} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container" style={{ textAlign: 'center', marginBottom:'40px'}}>
                            <div className="parent">
                                <div className="child">
                                    <div className="overflow-hidden img">
                                    <img loading='lazy' src={partner42} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img">
                                        <img loading='lazy' src={partner44} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img d-none">
                                        <img loading='lazy' src={partner44} alt="" />
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="overflow-hidden img d-none">
                                        <img loading='lazy' src={partner44} alt="" />
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        {/* <div className="container" style={{textAlign:'center',marginTop:'20px',marginBottom:'40px'}}>
                            <div className="row" style={{gap:'10px'}}>
                                <div className="col-sm">
                                <div className="overflow-hidden img">
                                    <img loading='lazy' src={partner} alt="" />
                                </div>
                                </div>
                                <div className="col-sm">
                                <div className="overflow-hidden img">
                                    <img loading='lazy' src={partner2} alt="" />
                                </div>
                                </div>
                                <div className="col-sm">
                                <div className="overflow-hidden img">
                                    <img loading='lazy' src={partner3} alt="" />
                                </div>
                                </div>
                                <div className="col-sm">
                                <div className="overflow-hidden img">
                                    <img loading='lazy' src={partner4} alt="" />
                                </div>
                                </div>
                            </div> */}
                        {/* </div> */}
                    </div>
                    <div className="aboutMissionPartner" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <a
                        href="#."
                        className="d-block text-decoration-none text-center btnPartner"
                        onClick={() => { openPopup(); }}
                      >Request To Become An Alliance Partner</a>
                    </div>
                  
                    {isPopupOpen && (
                        <div className="popup-overlay">
                            <div className="popup">
                                <button className="close-popup cursor-pointer" onClick={closePopup}>
                                </button>
                                <MembershipForm closePopup={closePopup} showBackground={false} isModal={true}/>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
