import React from 'react'
import member_1 from './../../../assets/images/about/Niloufar.jpeg'
import member_2 from './../../../assets/images/about/member_2.jpg'
import member_3 from './../../../assets/images/about/member_3.jpg'
import member_4 from './../../../assets/images/about/member_4.jpg'
import member_5 from './../../../assets/images/about/member_5.jpg'
import member_6 from './../../../assets/images/about/member_6.jpg'
import member_7 from './../../../assets/images/about/member_7.jpg'
import member_8 from './../../../assets/images/about/member_8.jpg'
import member_9 from './../../../assets/images/about/member_9.jpg'
import member_10 from './../../../assets/images/about/member_10.jpg'
import member_11 from './../../../assets/images/about/member_11.jpg'
import member_12 from './../../../assets/images/about/member_12.jpg'
import member_13 from './../../../assets/images/about/member_13.jpg'
import member_14 from './../../../assets/images/about/member_14.jpg'
import member_15 from './../../../assets/images/about/member_15.jpg'
import nicola_vita from './../../../assets/images/about/Nicola-Vita.jpg'
import raj_sethia from './../../../assets/images/about/Raj-Sethia.jpg'
import ozel_coskuner from './../../../assets/images/about/Ozel-Coskuner.jpg'
import AhmedHassanMember from './../../../assets/images/about/AhmedHassanMember.png'
import felix_osok from './../../../assets/images/about/Felix-Osok.jpg'
import diana_filatova from './../../../assets/images/about/Diana.jpg'
import jessica_fernanda from './../../../assets/images/about/Jessica-Fernanda-Prieto.jpg'
import emanuela_pia from './../../../assets/images/about/Emanuela-Pia-Viglione.jpg'
import marco_roux from './../../../assets/images/about/Marco-Roux.jpg'
import dana_maleh from './../../../assets/images/about/Dana_Maleh.jpg'
import PaulaErikonMember from './../../../assets/images/about/PaulaErikonMember.png'
import PatrickKato from './../../../assets/images/about/patrick-kato.jpeg'
import './Teams.css';
const SingleMemberCardModel = (props) => {
    return (
        <div className="card modelCard card_header_dialog container-fluid">
            {
                props.sendId == 1 &&
                <div className="row">
                    <div className="col-2 modelImgCnt">
                        <img loading='lazy' src={member_1} className="modelContentImg img-fluid rounded-start" alt="..." />
                    </div>
                    <div className="col modelCntPara">
                        <div className="card-body">
                            <h1 className="d-block memberHeading">Niloufar Gharavi</h1>
                            <h5 className="d-block subTeamName">Founder & Secretary General</h5>
                            <p className="memberParaDesc">
                                Is one of the leading entrepreneurial and systemic designers globally. She has a BA in Architecture from University of Isfahan, Iran, and the Architectural Association in London (AA), MA in Design from Oslo School of Architecture and Design (AHO) and the Royal Danish Academy (KADK).
                            </p>
                            <p className="memberParaDesc">
                                She is founder of the world’s first design-driven venture studio, having built successful companies and projects under its portfolio - varied from tech startups to humanitarian large scale projects together with organizations such as NRC, IOM, UNDP, UDI, Red Cross, etc.
                            </p>
                            <p className="memberParaDesc">
                                She founded Euro Nordic Funding Alliance to make public funding more accessible to a lot of serial entrepreneurs like herself.
                            </p>
                            <p className="memberParaDesc">
                                She is author of methodologies such as :
                                Design-Driven Entrepreneurship
                                @Home In Transition (empowering asylum seekers and refugees to become employable as well as self-employed)
                                Oslo Welcome Center - Cooperative for migrants
                            </p>
                        </div>
                    </div>
                </div>
            }

            {
                props.sendId == 2 &&
                <div className="row">
                    <div className="col-md-4">
                        <img loading='lazy' src={member_6} className="modelContentImg img-fluid rounded-start" alt="..." />
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">
                            <h1 className="d-block memberHeading">Tom O. Kleppesto</h1>
                            <h5 className="d-block subTeamName">Chairman, ENFA Norway</h5>
                            <p className="memberParaDesc">
                            (MSc) is the General Manager for the Maritime Oslofjord Alliance, comprising the three maritime and ocean industry associations Oslo Shipowners’ Association, Ocean Industry Forum Oslofjord, and Shipping & Offshore Network.
                            </p>
                            <p className="memberParaDesc">
                            Shipping & Offshore Network was one of 19 partners in the Go LNG project, with Klaipėda Science and Technology Park as lead partner (2016–19), together with 20 partners in the Blue Supply Chain project (2022-2025).
                            </p>
                            <p className="memberParaDesc">
                            His professional background includes executive education, talent management, entrepreneurship, innovation, and media.
                            </p>
                            <p className="memberParaDesc">
                            Tom is Chairman and Co-founder of the Lithuanian-Norwegian Chamber of Commerce. He has been involved with the Rafto Foundation for Human Rights.
                            </p>
                        </div>
                    </div>
                </div>
            }

            {
                props.sendId == 3 &&
                <div className="row">
                    <div className="col-md-4">
                        <img loading='lazy' src={member_3} className="modelContentImg img-fluid rounded-start" alt="..." />
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">
                            <h1 className="d-block memberHeading">Carlos Franco Uliaque</h1>
                            <h5 className="d-block subTeamName">Global Board Of Directors, ENFA Spain</h5>
                            <p className="memberParaDesc">
                                Senior EU Project Manager and President of Association of Developia Foundation. He has a degree in Economics University of Zaragoza, has a master’s in leadership from USJ and of International Trade from Paris XIII University in addition to a Maîtrise in Economic Sciences at Aix-Marseille University.
                            </p>
                            <p className="memberParaDesc">
                                Carlos has over 20 years of experience as European Project manager in both the private and the public sectors. He directly led over 200 projects in such different fields: training, business innovation, economic development, entrepreneurship, local and regional politics, energy efficiency, renewable energy, sustainable development social affairs, gender equality in the workplace, youth, human resources management, equal opportunities, etc. and applying for all kinds of European programs: H2020, Interreg, Erasmus, Leonardo, Progress, EuropeAid, etc.
                            </p>
                            <p className="memberParaDesc">
                                He is a member of the council of the University of Zaragoza, on the board of the Association for Self-employed Workers in Aragon (ATA), Spain and member of the council of protection of the environment in Aragon, Member of the Internationalisation Council of CEOE Aragón (Employers' Association).
                            </p>

                        </div>
                    </div>
                </div>
            }

            {
                props.sendId == 4 &&
                <div className="row">
                    <div className="col-md-4">
                        <img loading='lazy' src={nicola_vita} className="modelContentImg img-fluid rounded-start" alt="..." />
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">
                        <h1 className="d-block memberHeading">Nicola Vita</h1>
                        <h5 className="d-block subTeamName">Global Board Of Directors, ENFA Italy</h5>
                            <p className="memberParaDesc">
                            Policy development specialist with over 10 years’ experience in the area of the Cohesion Policy.
                            </p>
                            <p className="memberParaDesc">
                            In 2017 he was appointed as manager of the Italian branch of the European Leader Association for Rural Development.
                            </p>
                            <p className="memberParaDesc">
                            In 2022 he was designated as main representative of the Italian Industry Partnership in the Monitoring Committee of the Interreg Greece - Italy Programme 2021/2027.
                            </p>
                            <p className="memberParaDesc">
                            In 2023 he was elected as Vice-President at CONFIMI Industria Basilicata, one of the major Italian industry SME network with more than 40,000 associated firms countrywide.
                            </p>
                        </div>
                    </div>
                </div>
            }

            {
                props.sendId == 5 &&
                <div className="row">
                    <div className="col-md-4">
                        <img loading='lazy' src="/static/media/comminsonnNew.446384447eace037bb00.png" className="modelContentImg img-fluid rounded-start" alt="..." />
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">

                           <h1 className="d-block memberHeading">Anna M. Gentile</h1>
                           <h5 className="d-block subTeamName">ENFA Italy</h5>
                            <p className="memberParaDesc">
                            Graduated in economics; she holds a MA in Territorial Planning. She has been working for about twenty years at the top of national-level business firms in the field of consulting services to Public Administrations and companies.
                            </p>
                            <p className="memberParaDesc">
                            Over time she acquired strong skills, especially in the domain of entrepreneurship and self-employment. She has also contributed to the implementation of several important transnational Projects.
                            </p>
                            <p className="memberParaDesc">
                            Moreover, in 2015/2016, she coordinated the teams in charge of the development of the ESF ROPs 2014/2020 on behalf of Basilicata Region and Abruzzo Region.
                            </p>
                            <p className="memberParaDesc">
                            In 2020 she was appointed as Steering Committee member at the Energy Cluster of Basilicata Region.
                            </p>
                        </div>
                    </div>
                </div>
            }

            {
                props.sendId == 6 &&
                <div className="row">
                    <div className="col-md-4">
                        <img loading='lazy' src={member_4} className="modelContentImg img-fluid rounded-start" alt="..." />
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">

                        <h1 className="d-block memberHeading">Alessandro Carbone</h1>
                        <h5 className="d-block subTeamName">Global Board Of Directors, ENFA Cyprus</h5>
                            <p className="memberParaDesc">
                            Master in Social Work and Divinity, he is trainer and EU Project Manager since 1996 first as junior, later on as writer and implementer of funded programs.
                            </p>
                            <p className="memberParaDesc">
                            His expertise and experience enables him to write and coordinate multicultural partnership, consult organisations and supervise professionals working on project with public fundings.
                            </p>
                            <p className="memberParaDesc">
                            He matches the above with competences related to the fields of social innovation and entrepreneurship.
                            </p>
                            <p className="memberParaDesc">
                            He is the director of T-HAP LTD, consulting company in Cyprus
                            </p>    
                        </div>
                    </div>
                </div>
            }

            {
                props.sendId == 7 &&
                <div className="row">
                    <div className="col-md-4">
                        <img loading='lazy' src={raj_sethia} className="modelContentImg img-fluid rounded-start" alt="..." />
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">

                        <h1 className="d-block memberHeading">Raj Sethai</h1>
                        <h5 className="d-block subTeamName">Global Board Of Directors, ENFA Austria</h5>
                            <p className="memberParaDesc">
                            Raj Sethia, is ENFA`s representative in Austria and a member of the global board of directors at ENFA.
                            </p>
                            <p className="memberParaDesc">
                            Raj is the Founder & CEO of Meta4 Innovations, an independent research organization dedicated to sustainable social innovation and global transformation. With over two decades of international experience spanning the USA, Europe, and Asia, Raj brings a wealth of knowledge and expertise to our team.
                            </p>
                            <p className="memberParaDesc">
                            His background includes a distinguished tenure at the World Bank in Washington, DC, where he served as an Organizational Development Expert and Change Management Facilitator for teams in the East Asia Pacific and South East Asia regions. Raj's fluency in eight languages and his insatiable “Wanderlust” give him a competitive edge in leveraging diversity within international teams.
                            </p>
                            <p className="memberParaDesc">
                            When Raj is not busy leading transformative projects with his pan-European network or delivering keynote speeches, you might find him back in the lecture halls of the University of Klagenfurt molding the minds of the next generation, speaking at a TEDx event, writing poetry, or discussing spiritual intelligence.
                            </p>
                            <p className="memberParaDesc">
                            We’re proud to have Raj on our team, and we know you’ll enjoy getting to know him too. "How and where," you ask? At our summit in Stockholm, of course! Come and gain insights from his extensive experience.
                            </p>
                        </div>
                    </div>
                </div>
            }

            {
                props.sendId == 8 &&
                <div className="row">
                    <div className="col-md-4">
                        <img loading='lazy' src={ozel_coskuner} className="modelContentImg img-fluid rounded-start" alt="..." />
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">

                        <h1 className="d-block memberHeading">Oz El Coskuner</h1>
                        <h5 className="d-block subTeamName">Global Board Of Directors, ENFA Austria</h5>
                            <p className="memberParaDesc">
                            Oz El Coskuner is a part of the ENFA Global Board Of Directors & National Office representative in Austria.
                            </p>
                            <p className="memberParaDesc">
                            Oz is the CEO of a leading research and development company that focuses and invests on educational and agricultural technologies in Vienna, Austria. He and his team spearheads initiatives with a strategic focus on artificial intelligence, virtual reality, mixed reality, and augmented reality. His innovative leadership drives transformative advancements towards positioning his company at the forefront of technological progress.
                            </p>
                            <p className="memberParaDesc">
                            Oz also serves as the president of the Austrian Inclusive Society, a prominent organization that is dedicated to fostering inclusion, promoting educational equity, and empowering entrepreneurship. Under his guidance, the NGO actively works to bridge the digital divide, ensuring equitable access to technological resources by democratization of AI, internet and online education for all members of society.
                            </p>
                            <p className="memberParaDesc">
                            Before Europe, his diverse portfolio covered a decade of service in Southeast Asia, particularly in Malaysia and Singapore. During this period, he consulted for numerous universities on international relations and expansion and advised the Ministry of Higher Education in Malaysia on international education policy, student migration policy and technological transformation. His contributions have left a lasting impact on the region's educational reforms and technological advancements.
                            </p>
                        </div>
                    </div>
                </div>
            }

            {
                props.sendId == 9 &&
                <div className="row">
                    <div className="col-md-4">
                        <img loading='lazy' src={AhmedHassanMember} className="modelContentImg img-fluid rounded-start" alt="..." />
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">

                        <h1 className="d-block memberHeading">Ahmed Hassan</h1>
                        <h5 className="d-block subTeamName">ENFA Luxemburg</h5>
                            <p className="memberParaDesc">
                            Ahmed Hassan is a Social Development and Projects Manager with over 20 years of experience in leading impactful local and international initiatives. Currently the CEO of AGD Luxembourg and Vice President of several European organizations, he focuses on social accountability, governance, and sustainable development. Ahmed has a strong background in securing and managing EU funding, employing a blended financing approach that combines grants, CSR, and investment financing to maximize impact. He is committed to intercultural learning and environmental protection and actively consults on CSR, safety, and sustainability projects across Africa and Europe.
                            </p>
                            <p className="memberParaDesc">
                            Ahmed’s role at AGD Luxembourg, a national ENFA network, supports organizations in expanding funding eligibility through EU and blended financing models. As the project leader of CYBER CITIZENS, he organizes media literacy and intercultural education programs for adults, while his work in the CORPI Project addresses anti-racism in sports through training and digital campaigns. Ahmed is PMP-certified and holds a Master’s in Corporate Social Responsibility from HBRS University Bonn, along with a Master’s in International Tourism and Hospitality Management from GBSB Barcelona. His work reflects a dedication to fostering resilience, sustainable development, and meaningful intercultural connections across regions.
                            </p>
                        </div>
                    </div>
                </div>
            }

{
                props.sendId == 10 &&
                <div className="row">
                    <div className="col-md-4">
                        <img loading='lazy' src={felix_osok} className="modelContentImg img-fluid rounded-start" alt="..." />
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">

                        <h1 className="d-block memberHeading">Felix Osok</h1>
                        <h5 className="d-block subTeamName">ENFA Kenya</h5>
                            <p className="memberParaDesc">
                            Felix brings a wealth of knowledge in international development cooperation with a special focus on innovative financing mechanisms. He has extensive experience in leveraging both private and public funding opportunities, trade, and market development across continents by strengthening partnerships that drive growth, investments, and entrepreneurship, with a particular emphasis on Africa, known as the world’s most exciting next growth market with the highest return on investment.
                            </p>
                            <p className="memberParaDesc">
                            Felix is a serial entrepreneur and drives this passionate mission through his current role as the Group CEO of The Aquaculture and Blue Economy Consortium (TAC). TAC works on building a resilient and sustainable aquaculture ecosystem for productivity growth and job creation in the East Africa region.
                            </p>
                            <p className="memberParaDesc">
                            When not at work, Felix enjoys traveling and supervising corporate social responsibility (CSR) activities leveraged by the consortium partners, as well as participating in voluntary community social initiatives (CSIs) and village life in his rural home and farm in the western region of Kenya. He also enjoys sailing on Lake Victoria and experiencing the unique biodiversity in this lake region.
                            </p>
                            <p className="memberParaDesc">
                            Felix brings a unique flair to our team with his diverse interests. We are confident in Felix’s expertise in building cross-border alliances and consortiums, leveraging his vast networks and knowledge base for the benefit of ENFA’s members and partners.
                            </p>
                        </div>
                    </div>
                </div>
            }

            {
                props.sendId == 12 &&
                <div className="row">
                    <div className="col-md-4">
                        <img loading='lazy' src={member_15} className="modelContentImg img-fluid rounded-start" alt="..." />
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">
                        <h1 className="d-block memberHeading">Maria Tintul</h1>
                        <h5 className="d-block subTeamName">Ukraine Projects Advisor</h5>
                            <p className="memberParaDesc">
                                Having 9 years of experience in IT Business Development Mariia has had a chance to work in various industries: IT outsourcing, Product Development, Social and Cultural Heritage projects supported by governmental Fund, working on social projects with UNICEF, curating Service business in creating business XR applications of any sort (including the work with Telenor Norway) and now finally creating a Ukrainian scale up startups support program by being in investment and startup growth.
                                This Program just won the Accelerator of the Year award by the Ukrainian Startup Fund, the biggest governmental organisation working with startups in the country.
                            </p>
                            <p className="memberParaDesc">
                                Mariia can combine startup thinking and corporate experience in order to try to validate the relevance and impact of new ideas and concepts emerging on the market. The Sales, PR and Partnerships background allows her to always see the roadmap, even when we only just discuss the ideas.
                            </p>
                        </div>
                    </div>
                </div>
            }

            {
                props.sendId == 13 &&
                <div className="row">
                    <div className="col-md-4">
                        <img loading='lazy' src={member_14} className="modelContentImg img-fluid rounded-start" alt="..." />
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">
                        <h1 className="d-block memberHeading">Villeman Vinje</h1>
                        <h5 className="d-block subTeamName">Strategic Advisor</h5>
                            <p className="memberParaDesc">
                            Economist with 20 years of broad and varied experiences in this domain. Educated as an Economist from University of Edinburgh, a MSc in Business Strategy from BI Norwegian Business School and an Authorized Financial Analyst at NHH, he initially started out with Business Strategy within Management Consulting.
                            </p>
                            <p className="memberParaDesc">
                            His experience as a political advisor at the Finance Committee at the Parliament, and as an Economist at Civita, a liberal, market oriented Think Tank, shifted his focus to complex macroeconomic topics, such as tax reforms, policy for innovation and value creation, reform of the public sector and incentives and policies for transforming the Economy to increase sustainability and circularity.
                            </p>
                            <p className="memberParaDesc">
                            The last six years he has worked with these topics as a Partner at NyAnalyse, an Economic Consultancy while also investing in and advising start-ups.
                            </p>
                        </div>
                    </div>
                </div>
            }

            {
                props.sendId == 14 &&
                <div className="row">
                    <div className="col-md-4">
                        <img loading='lazy' src={diana_filatova} className="modelContentImg img-fluid rounded-start" alt="..." />
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">
                        <h1 className="d-block memberHeading">Diana Filatova</h1>
                        <h5 className="d-block subTeamName">PR Manager</h5>
                            <p className="memberParaDesc">
                            Diana plays a crucial role in managing public relations, communications and the vision for ENFA, ensuring the organization's message is effectively conveyed to the public and stakeholders for its repuration. As Executive Deputy, she assists in overseeing the strategic and operational aspects of ENFA, working closely with the General Manager to drive the organization's mission and initiatives forward. Her dedication and expertise are invaluable to the success and growth of ENFA.
                            </p>
                            <p className="memberParaDesc">
                            Outside the office, Diana is passionate about social latin dancing, which adds a unique and vibrant element to our team culture becoming a part of outside the office life.
                            </p>
                        </div>
                    </div>
                </div>
            }

            {
                props.sendId == 15 &&
                <div className="row">
                    <div className="col-md-4">
                        <img loading='lazy' src={jessica_fernanda} className="modelContentImg img-fluid rounded-start" alt="..." />
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">
                        <h1 className="d-block memberHeading">Jessica Fernanda Prieto</h1>
                        <h5 className="d-block subTeamName">Social Media Specialist</h5>
                            <p className="memberParaDesc">
                            Jessica is based in London, but she herself is a US native with Colombian roots. She brings a dynamic energy and deep understanding of digital marketing to our team.
                            </p>
                            <p className="memberParaDesc">
                            Over the years, she has done projects with renowned brands like Space NK, TED Talk, Nissan, Atlantis The Royal Dubai and many more. With this baggage of experience and knowledge, she came to the company on a part-time basis last year to bring a new perspective on communication and advise the team.
                            </p>
                            <p className="memberParaDesc">
                            When Jess isn’t strategizing the next big social media campaign, or not working on her next book she enjoys traveling all around the world, which brings a creative flair to our team culture.
                            </p>
                        </div>
                    </div>
                </div>
            }

            {
                props.sendId == 16 &&
                <div className="row">
                    <div className="col-md-4">
                        <img loading='lazy' src={emanuela_pia} className="modelContentImg img-fluid rounded-start" alt="..." />
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">
                        <h1 className="d-block memberHeading">Emanuela Pia Viglione</h1>
                        <h5 className="d-block subTeamName">ENFA Project Manager</h5>
                            <p className="memberParaDesc">
                            Emanuela brings a wealth of experience in entrepreneurship and innovation. With a background in political science and international studies, her focus on geoeconomics and international policies informs her strategic approach, shaping innovative solutions at the nexus of business and global relations.
                            </p>
                            <p className="memberParaDesc">
                            Currently, Emanuela has been working with grants for a while now, updating her knowledge daily to stay aware of all new opportunities for her clients.
                            </p>
                            <p className="memberParaDesc">
                            If you are thinking of becoming a member of our team, it is possible that Emanuela will be your manager—in which case, consider yourself lucky! 
                            </p>
                        </div>
                    </div>
                </div>
            }

            {
                props.sendId == 18 &&
                <div className="row">
                    <div className="col-md-4">
                        <img loading='lazy' src={marco_roux} className="modelContentImg img-fluid rounded-start" alt="..." />
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">
                        <h1 className="d-block memberHeading">Marco Roux</h1>
                        <h5 className="d-block subTeamName">Relationship Manager</h5>
                            <p className="memberParaDesc">
                            Marco Roux holds a Master of Arts in Human Rights from the University of Vienna, as well as a master's in Politics and Government where he focused on education policies for migrant children and adolescents, and a master´s in European Studies- Management of EU projects. He has collaborated on projects in the fields of human rights and education providing capacity-building workshops and trainings on employability skills for youth and ICT training for seniors. 
                            </p>
                            <p className="memberParaDesc">
                            He has strong experience in international project management, including Erasmus+ KA2 projects, quality assurance, ISO standards, and reporting.  He has experience in organizing trainings for young people and adults to promote lifelong learning activities, and professional and social improvement courses.
                            </p>
                        </div>
                    </div>
                </div>
            }

            {
                props.sendId == 19 &&
                <div className="row">
                    <div className="col-md-4">
                        <img loading='lazy' src={dana_maleh} className="modelContentImg img-fluid rounded-start" alt="..." />
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">
                        <h1 className="d-block memberHeading">Dana Maleh</h1>
                        <h5 className="d-block subTeamName">Relationship Manager</h5>
                            <p className="memberParaDesc">
                            One of the key player in ENFA team. As a dedicated Project Manager, Coordinator, and Community Manager, Dana brings a wealth of expertise and passion to everything she does. 
                            </p>
                            <p className="memberParaDesc">
                            Dana plays a crucial role in the research and development of educational projects, blending her skills in project coordination with a deep understanding of community engagement. With a diploma in event management and a background in associated research, she ensures that every project she manages is meticulously planned and executed, making a lasting impact aligned with our organization’s goals.
                            </p>
                        </div>
                    </div>
                </div>
            }

            {
                props.sendId == 23 &&
                <div className="row">
                    <div className="col-md-4">
                        <img loading='lazy' src={PaulaErikonMember} className="modelContentImg img-fluid rounded-start" alt="..." />
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">
                        <h1 className="d-block memberHeading">Paula Erikon</h1>
                        <h5 className="d-block subTeamName">Relationship Manager</h5>
                            <p className="memberParaDesc">
                            Paula is a seasoned leader in design, product, marketing, and customer experience, with a proven track record of building start-ups, managing teams, and developing business strategies. She excels at turning ideas into innovative, customer-centric products and shares her knowledge through educational programs and the community she leads. Her strong network and passion for facilitation help her connect key players with investors, driving business growth. 
                            </p>
                            <p className="memberParaDesc">
                            A career highlight includes co-founding a proptech start-up, where Paula led design, introduced AR/VR technology to the Swedish market, and developed an early SaaS platform. She’s pitched to investors, managed teams, and coordinated stakeholders, all while driving growth. Her role on the board of a solar energy company further reflects her commitment to sustainability.
                            </p>
                            <p className="memberParaDesc">
                            Known for blending creativity with business acumen, Paula thrives on collaboration and spotting opportunities in any situation. With a user-first approach and positive mindset, she’s ready to bring energy, leadership, and success to any future venture.
                            </p>
                        </div>
                    </div>
                </div>
            }

{
                props.sendId == 24 &&
                <div className="row">
                    <div className="col-md-4">
                        <img loading='lazy' src={PatrickKato} className="modelContentImg img-fluid rounded-start" alt="..." />
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">
                        <h1 className="d-block memberHeading">Patrick Kato</h1>
                        <h5 className="d-block subTeamName">ENFA Sweden</h5>
                            <p className="memberParaDesc">
                            Patrick is a seasoned executive with extensive experience in sales leadership, strategic consulting, and market expansion across EMEA, APAC, and the Americas. With a proven track record of driving growth and fostering partnerships, he excels in developing and executing innovative strategies that enhance market presence and optimize sales performance.
                            </p>
                            <p className="memberParaDesc">
                            Throughout his career, Patrick has honed his skills in building strong relationships with partners and clients, enabling organizations to achieve significant revenue increases and strengthen their competitive positioning. His expertise in channel sales and enterprise sales management equips him to lead cross-border teams effectively, mentor talent, and navigate complex market dynamics all over the world.
                            </p>
                            <p className="memberParaDesc">
                            Patrick’s strategic vision and ability to align sales initiatives with business goals ensure that organizations not only meet but exceed their performance targets. He is adept at identifying new opportunities for growth and innovation, which positions him as a most valuable asset to any company looking to expand its market reach and enhance its operational effectiveness.
                            </p>
                            <p className="memberParaDesc">
                            With a strong background in both technology and sales strategy, Patrick brings a unique perspective that empowers organizations to thrive in competitive landscapes and achieve sustainable success.
                            </p>
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default SingleMemberCardModel