import React, { useEffect, useState } from 'react'
// import christoffer_omberg from './../../../assets/images/about/christoffer-omberg.png'
// import marius_wang from './../../../assets/images/about/marius-wang.jpg'
// import fredrik_dehli from './../../../assets/images/about/fredrik-dehli.jpg'
// import astrea_kustas from './../../../assets/images/about/astrea-kustas.jpg'
// import lars_erik_fjosne_eide from './../../../assets/images/about/lars-erik-fjosne-eide.jpg'
// import aleksander_moberg from './../../../assets/images/about/aleksander-moberg.jpg'
// import ida_marie_grimsrud from './../../../assets/images/about/ida-marie-grimsrud.jpg'
// import andreas_kustas from './../../../assets/images/about/andreas-kustas.jpg'
import polygon_white from './../../../assets/images/polygon-white.svg'
//import linked_in from './../../../assets/images/linkedin.svg'
import member_1 from './../../../assets/images/about/Niloufar.jpeg'
import member_6 from './../../../assets/images/about/member_6.jpg'
import member_3 from './../../../assets/images/about/member_3.jpg'
import raj_sethia from './../../../assets/images/about/Raj-Sethia.jpg'
import ozel_coskuner from './../../../assets/images/about/Ozel-Coskuner.jpg'
import andreas_stefanidis from './../../../assets/images/about/Andreas-Stefanidis.jpg'
import felix_osok from './../../../assets/images/about/Felix-Osok.jpg'
import raul_saenz from './../../../assets/images/about/Raul-Saenz-De-Inestrillas.jpg'
import diana_filatova from './../../../assets/images/about/Diana.jpg'
import jessica_fernanda from './../../../assets/images/about/Jessica-Fernanda-Prieto.jpg'
import emanuela_pia from './../../../assets/images/about/Emanuela-Pia-Viglione.jpg'
import teresa_tito from './../../../assets/images/about/Teresa-Lo-Tito.jpg'
import marco_roux from './../../../assets/images/about/Marco-Roux.jpg'
import dana_maleh from './../../../assets/images/about/Dana_Maleh.jpg'
import ambra_borsalino from './../../../assets/images/about/Ambra-Borsalino.jpg'
import nicola_vita from './../../../assets/images/about/Nicola-Vita.jpg'
import member_7 from './../../../assets/images/about/member_7.jpg'
import member_9 from './../../../assets/images/about/member_9.jpg'
import member_4 from './../../../assets/images/about/member_4.jpg'
import member_14 from './../../../assets/images/about/member_14.jpg'
import member_15 from './../../../assets/images/about/member_15.jpg'
import IdaJengMember from './../../../assets/images/about/IdaJengMember.png'
import PaulaErikonMember from './../../../assets/images/about/PaulaErikonMember.png'
import AhmedHassanMember from './../../../assets/images/about/AhmedHassanMember.png'
import PatrickKato from './../../../assets/images/about/patrick-kato.jpeg'


import './Teams.css';
import SingleMemberCardModel from './SingleMemberCardModel'

const TeamComponent = () => {
    const [toggle,setToggle] = useState(false);
    const [id,setTid] = useState(0);
    
    const clickHandle = (e) =>{
        setToggle(!toggle);
        setTid(e)
    }
    
    return (
        <>
            <div className="aboutMeetTeamSection">
                <div className="aboutMeetTeamBG">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4" style={{ marginBottom: '30px', marginTop: '20px' }}>
                                <div className="meetTeamArrowW">
                                    <img loading='lazy' src={polygon_white} alt="" />
                                </div>
                                <div className="titleMeetTeam">MEET THE TEAM</div>
                            </div>

                            {/* ------------1 -------------------- */}
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                <div className="meetCardTeam">
                                    <div className="overflow-hidden meetPhoto">
                                        <img loading='lazy' src={member_1} alt=""/>
                                    </div>
                                    <div className="text-center meetCnt">
                                        <div className="flex">
                                            <div> 
                                                <span className="d-block teamName">Niloufar Gharavi</span>
                                            </div>
                                        </div>
                                        <span className="d-block teamPosition">Founder & Secretary General</span>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                            <button type="button" className="btnLoginCard btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalCenter" onClick={()=>clickHandle(1)}>Read Bio</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                             {/* ------------2-------------------- */}
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                <div className="meetCardTeam">
                                    <div className="overflow-hidden meetPhoto">
                                        <img loading='lazy' src={member_6} alt="" />
                                    </div>
                                    <div className="text-center meetCnt">
                                    <div className="flex">
                                            <div> 
                                                <span className="d-block teamName">Tom O. Kleppesto</span>
                                                <span className="d-block teamPosition">Chairman, ENFA Norway</span>
                                            </div>  
                                        </div>
                                        
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                            <button type="button" className="btnLoginCard btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalCenter" onClick={()=>clickHandle(2)}>Read Bio</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                             {/* ------------3 -------------------- */}
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                <div className="meetCardTeam">
                                    <div className="overflow-hidden meetPhoto">
                                        <img loading='lazy' src={member_3} alt="" />
                                    </div>
                                    <div className="text-center meetCnt">
                                        <div className="flex">
                                            <div>
                                                <span className="d-block teamName">Carlos Franco Uliaque</span>
                                            </div>
                                        </div>
                                        <span className="d-block teamPosition">Global Board Of Directors <br></br> ENFA Spain</span>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                        <button type="button" className="btnLoginCard btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalCenter" onClick={()=>clickHandle(3)}>Read Bio</button>
                                        </div>
                                    </div>
                                </div>
                            </div>



                             {/* ------------4 -------------------- */}
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                <div className="meetCardTeam">
                                    <div className="overflow-hidden meetPhoto">
                                        <img loading='lazy' src={nicola_vita} alt="" style={{height:'100%'}}/>
                                    </div>
                                    <div className="text-center meetCnt">
                                    <div className="flex">
                                            
                                            <div>
                                                <span className="d-block teamName">Nicola Vita</span>
                                            </div>
                                          
                                        </div>
                                        <span className="d-block teamPosition">Global Board Of Directors <br></br> ENFA Italy</span>

                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                        <button type="button" className="btnLoginCard btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalCenter" onClick={()=>clickHandle(4)}>Read Bio</button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* --------5-------- */}
                            {/* <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                <div className="meetCardTeam">
                                    <div className="overflow-hidden meetPhoto">
                                        <img loading='lazy' src="/static/media/comminsonnNew.446384447eace037bb00.png" alt="" />
                                    </div>
                                    <div className="text-center meetCnt">
                                        <div className="flex">
                                            <div>
                                                <span className="d-block teamName">Anna M. Gentile</span>
                                            </div>                                       
                                        </div>
                                        <span className="d-block teamPosition">ENFA Italy</span>

                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                        <button type="button" className="btnLoginCard btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalCenter" onClick={()=>clickHandle(5)}>Read Bio</button>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            {/* ----------6 --------------- */}
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                <div className="meetCardTeam">
                                    <div className="overflow-hidden meetPhoto">
                                        <img loading='lazy' src={member_4} alt="" />
                                    </div>
                                    <div className="text-center meetCnt">
                                    <div className="flex">
                                            <div>
                                                <span className="d-block teamName">Alessandro Carbone</span>
                                            </div>                                       
                                        </div>
                                        <span className="d-block teamPosition">Global Board Of Directors <br></br> ENFA Cyprus</span>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                        <button type="button" className="btnLoginCard btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalCenter" onClick={()=>clickHandle(6)}>Read Bio</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            

                            {/* ----------7----------- */}
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                <div className="meetCardTeam">
                                    <div className="overflow-hidden meetPhoto">
                                        <img loading='lazy' src={raj_sethia} alt="" style={{height:'100%'}}/>
                                    </div>
                                    <div className="text-center meetCnt">
                                         <div className="flex">
                                            <div>
                                                <span className="d-block teamName">Raj Sethai</span>
                                            </div>                                       
                                        </div>
                                        <span className="d-block teamPosition">Global Board Of Directors <br></br> ENFA Austria</span>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                            <button type="button" className="btnLoginCard btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalCenter" onClick={()=>clickHandle(7)}>Read Bio</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* ------------ 8 -------------- */}
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                <div className="meetCardTeam">
                                    <div className="overflow-hidden meetPhoto">
                                        <img loading='lazy' src={ozel_coskuner} alt="" />
                                    </div>
                                    <div className="text-center meetCnt">
                                         <div className="flex">
                                            <div>
                                                <span className="d-block teamName">Oz El Coskuner</span>
                                            </div>                                       
                                        </div>
                                        <span className="d-block teamPosition">Global Board Of Directors <br></br> ENFA Austria</span>

                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                            <button type="button" className="btnLoginCard btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalCenter" onClick={()=>clickHandle(8)}>Read Bio</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* ------------ 9 ----------- */}
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                <div className="meetCardTeam">
                                    <div className="overflow-hidden meetPhoto">
                                        <img loading='lazy' src={andreas_stefanidis} alt=""  />
                                    </div>
                                    <div className="text-center meetCnt">
                                    <div className="flex">
                                            <div>
                                                <span className="d-block teamName">Andreas Stefanidis</span>
                                            </div>                                       
                                        </div>
                                        <span className="d-block teamPosition">Global Board Of Directors <br></br> ENFA Greece</span>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                            {/* <button type="button" className="btnLoginCard btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalCenter">Read Bio</button> */}
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                <div className="meetCardTeam">
                                    <div className="overflow-hidden meetPhoto">
                                        <img loading='lazy' src={AhmedHassanMember} alt=""/>
                                    </div>
                                    <div className="text-center meetCnt">
                                    <div className="flex">
                                            <div>
                                                <span className="d-block teamName">Ahmed Hassan</span>
                                            </div>                                       
                                        </div>
                                        <span className="d-block teamPosition">ENFA Luxemburg</span>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                            <button type="button" className="btnLoginCard btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalCenter" onClick={()=>clickHandle(9)}>Read Bio</button>
                                        </div>

                                    </div>
                                </div>
                            </div>    

                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                <div className="meetCardTeam">
                                    <div className="overflow-hidden meetPhoto">
                                        <img loading='lazy' src={PatrickKato} alt=""/>
                                    </div>
                                    <div className="text-center meetCnt">
                                    <div className="flex">
                                            <div>
                                                <span className="d-block teamName">Patrick Kato</span>
                                            </div>                                       
                                        </div>
                                        <span className="d-block teamPosition">ENFA Sweden</span>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                            <button type="button" className="btnLoginCard btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalCenter" onClick={()=>clickHandle(24)}>Read Bio</button>
                                        </div>

                                    </div>
                                </div>
                            </div>    

                            {/* ------------ 10 ------------- */}
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                <div className="meetCardTeam">
                                    <div className="overflow-hidden meetPhoto">
                                        <img loading='lazy' src={felix_osok} alt=""  style={{height:'100%'}}/>
                                    </div>
                                    <div className="text-center meetCnt">
                                        <div className="flex">
                                            <div>
                                                <span className="d-block teamName">Felix Osok</span>
                                            </div>                                       
                                        </div>
                                        <span className="d-block teamPosition">ENFA Kenya</span>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                            <button type="button" className="btnLoginCard btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalCenter" onClick={()=>clickHandle(10)}>Read Bio</button>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            {/* ------------ 11 ------------- */}
                            {/* <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                <div className="meetCardTeam">
                                    <div className="overflow-hidden meetPhoto">
                                        <img loading='lazy' src={raul_saenz} alt=""  style={{height:'100%'}}/>
                                    </div>
                                    <div className="text-center meetCnt">
                                        <div className="flex">
                                            <div>
                                                <span className="d-block teamName">Raul Saenz De Inestrillas</span>
                                            </div>                                       
                                        </div>
                                        <span className="d-block teamPosition">ENFA UK</span>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                        </div>

                                    </div>
                                </div>
                            </div> */}

                            {/* ------------ 12 ------------- */}
                            {/*
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                <div className="meetCardTeam">
                                    <div className="overflow-hidden meetPhoto">
                                        <img loading='lazy' src={member_15} alt=""  />
                                    </div>
                                    <div className="text-center meetCnt">
                                    <div className="flex">
                                            <div>
                                                <span className="d-block teamName">Mariia Tintul</span>
                                            </div>                                       
                                        </div>
                                        <span className="d-block teamPosition">ENFA Ukraine</span>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                        <button type="button" className="btnLoginCard btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalCenter" onClick={()=>clickHandle(12)}>Read Bio</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            */}    
                            {/* ------------ 13 ------------- */}
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                <div className="meetCardTeam">
                                    <div className="overflow-hidden meetPhoto">
                                        <img loading='lazy' src={member_14} alt=""/>
                                    </div>
                                    <div className="text-center meetCnt">
                                    <div className="flex">
                                            <div>
                                                <span className="d-block teamName">Eirik Villeman Vinje</span>
                                            </div>                                       
                                        </div>
                                        <span className="d-block teamPosition">Strategic Advisor</span>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                        <button type="button" className="btnLoginCard btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalCenter" onClick={()=>clickHandle(13)}>Read Bio</button>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            {/* ------------ 14 ------------- */}
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                <div className="meetCardTeam">
                                    <div className="overflow-hidden meetPhoto">
                                        <img loading='lazy' src={diana_filatova} alt=""/>
                                    </div>
                                    <div className="text-center meetCnt">
                                    <div className="flex">
                                            <div>
                                                <span className="d-block teamName">Diana Filatova</span>
                                            </div>                                       
                                        </div>
                                        <span className="d-block teamPosition">PR Manager</span>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                            <button type="button" className="btnLoginCard btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalCenter" onClick={()=>clickHandle(14)}>Read Bio</button>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                <div className="meetCardTeam">
                                    <div className="overflow-hidden meetPhoto">
                                        <img loading='lazy' src={PaulaErikonMember} alt=""/>
                                    </div>
                                    <div className="text-center meetCnt">
                                    <div className="flex">
                                            <div>
                                                <span className="d-block teamName">Paula Erikon
                                                </span>
                                            </div>                                       
                                        </div>
                                        <span className="d-block teamPosition">Partnership Manager
                                        </span>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                            <button type="button" className="btnLoginCard btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalCenter" onClick={()=>clickHandle(23)}>Read Bio</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                                
                            {/* ------------ 15 ------------- */}
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                <div className="meetCardTeam">
                                    <div className="overflow-hidden meetPhoto">
                                        <img loading='lazy' src={jessica_fernanda} alt=""/>
                                    </div>
                                    <div className="text-center meetCnt">
                                    <div className="flex">
                                            <div>
                                                <span className="d-block teamName">Jessica Fernanda Prieto</span>
                                            </div>                                       
                                        </div>
                                        <span className="d-block teamPosition">Social Media Specialist</span>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                            <button type="button" className="btnLoginCard btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalCenter" onClick={()=>clickHandle(15)}>Read Bio</button>
                                        </div>

                                    </div>
                                </div>
                            </div>

                             {/* ------------ 16 ------------- */}
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                <div className="meetCardTeam">
                                    <div className="overflow-hidden meetPhoto">
                                        <img loading='lazy' src={emanuela_pia} alt=""  style={{height:'100%'}}/>
                                    </div>
                                    <div className="text-center meetCnt">
                                    <div className="flex">
                                            <div>
                                                <span className="d-block teamName">Emanuela Pia Viglione</span>
                                            </div>                                       
                                        </div>
                                        <span className="d-block teamPosition">Relationship Manager</span>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                            <button type="button" className="btnLoginCard btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalCenter" onClick={()=>clickHandle(16)}>Read Bio</button>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            {/* ------------ 17 ------------- */}
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                <div className="meetCardTeam">
                                    <div className="overflow-hidden meetPhoto">
                                        <img loading='lazy' src={teresa_tito} alt=""/>
                                    </div>
                                    <div className="text-center meetCnt">
                                    <div className="flex">
                                            <div>
                                                <span className="d-block teamName">Tereza Lo Tito</span>
                                            </div>                                       
                                        </div>
                                        <span className="d-block teamPosition">Relationship Manager</span>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                        {/* <button type="button" className="btnLoginCard btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalCenter" onClick={()=>clickHandle(15)}>Read Bio</button> */}
                                        </div>

                                    </div>
                                </div>
                            </div>

                            {/* ------------ 18 ------------- */}
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                <div className="meetCardTeam">
                                    <div className="overflow-hidden meetPhoto">
                                        <img loading='lazy' src={marco_roux} alt=""  style={{height:'100%'}}/>
                                    </div>
                                    <div className="text-center meetCnt">
                                    <div className="flex">
                                            <div>
                                                <span className="d-block teamName">Marco Roux</span>
                                            </div>                                       
                                        </div>
                                        <span className="d-block teamPosition">Relationship Manager</span>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                            <button type="button" className="btnLoginCard btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalCenter" onClick={()=>clickHandle(18)}>Read Bio</button>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            {/* ------------ 19 ------------- */}
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                <div className="meetCardTeam">
                                    <div className="overflow-hidden meetPhoto">
                                        <img loading='lazy' src={dana_maleh} alt=""  style={{height:'100%'}}/>
                                    </div>
                                    <div className="text-center meetCnt">
                                    <div className="flex">
                                            <div>
                                                <span className="d-block teamName">Dana Maleh</span>
                                            </div>                                       
                                        </div>
                                        <span className="d-block teamPosition">Relationship Manager</span>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                            <button type="button" className="btnLoginCard btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalCenter" onClick={()=>clickHandle(19)}>Read Bio</button>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            {/* ------------ 20 ------------- */}
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                <div className="meetCardTeam">
                                    <div className="overflow-hidden meetPhoto">
                                        <img loading='lazy' src={ambra_borsalino} alt=""  style={{height:'100%'}}/>
                                    </div>
                                    <div className="text-center meetCnt">
                                    <div className="flex">
                                            <div>
                                                <span className="d-block teamName">Ambra Borsalino</span>
                                            </div>                                       
                                        </div>
                                        <span className="d-block teamPosition">Relationship Manager</span>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                        {/* <button type="button" className="btnLoginCard btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalCenter" onClick={()=>clickHandle(15)}>Read Bio</button> */}
                                        </div>

                                    </div>
                                </div>
                            </div>

                            {/* ------------ 21 ------------- */}
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                <div className="meetCardTeam">
                                    <div className="overflow-hidden meetPhoto">
                                        <img loading='lazy' src={member_7} alt=""/>
                                    </div>
                                    <div className="text-center meetCnt">
                                    <div className="flex">
                                            <div>
                                                <span className="d-block teamName">Bhanu P. Singh</span>
                                            </div>                                       
                                        </div>
                                        <span className="d-block teamPosition">Operations</span>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                        {/* <button type="button" className="btnLoginCard btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalCenter" onClick={()=>clickHandle(15)}>Read Bio</button> */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/*
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                <div className="meetCardTeam">
                                    <div className="overflow-hidden meetPhoto">
                                        <img loading='lazy' src={member_9} alt=""/>
                                    </div>
                                    <div className="text-center meetCnt">
                                    <div className="flex">
                                            <div>
                                                <span className="d-block teamName">Raquel De Sousa</span>
                                            </div>                                       
                                        </div>
                                        <span className="d-block teamPosition">Research & Innovation</span>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>                                        
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                <div className="meetCardTeam">
                                    <div className="overflow-hidden meetPhoto">
                                        <img loading='lazy' src={IdaJengMember} alt=""/>
                                    </div>
                                    <div className="text-center meetCnt">
                                    <div className="flex">
                                            <div>
                                                <span className="d-block teamName">Ida Jeng
                                                </span>
                                            </div>                                       
                                        </div>
                                        <span className="d-block teamPosition">Project Manager
                                        </span>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                        {/* <button type="button" className="btnLoginCard btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalCenter" onClick={()=>clickHandle(15)}>Read Bio</button> */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <button type="button" className="btn-close btn-close-custom" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                          <SingleMemberCardModel sendId={id}/>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default TeamComponent