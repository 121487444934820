import React, { useEffect, useState } from 'react';
import './Slider.css';
import SummitMSilder24 from './SummitMSilder24';

const galleryPhotos = Array.from({ length: 30 }, (_, index) =>
  require(`../../../assets/images/summit2024/SliderSpeakers/img${index + 1}.JPG`)
);

const delay = 2500;

const getTranslatePercentage = () => {
  switch (true) {
    case window.innerWidth >= 1700:
      return 17;
    case window.innerWidth >= 1500:
      return 19;
    case window.innerWidth >= 1400:
      return 22;
    case window.innerWidth >= 1500:
      return 19;
    case window.innerWidth >= 1200:
      return 24;
    case window.innerWidth >= 1100:
      return 27;
    case window.innerWidth >= 1000:
      return 30;
    case window.innerWidth >= 992:
      return 31;
    case window.innerWidth >= 940:
      return 35;
    case window.innerWidth >= 800:
      return 37;
    case window.innerWidth >= 740:
      return 43;
    default: break;
  }
};

const SummitSlider24 = () => {
  const [index, setIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false); 
  const [isDialogOpen, setIsDialogOpen] = useState(false); 
  const [currentImage, setCurrentImage] = useState(null); 
  const timeoutRef = React.useRef(null);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  useEffect(() => {
    if (!isPaused) {
      resetTimeout();
      timeoutRef.current = setTimeout(
        () => setIndex((prevIndex) => (prevIndex === galleryPhotos.length - 1 ? 0 : prevIndex + 1)),
        delay
      );
    }

    return () => resetTimeout();
  }, [index, isPaused]);

  const openDialog = (image) => {
    setIsPaused(true); 
    setCurrentImage(image); 
    setIsDialogOpen(true); 
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setIsPaused(false); 
  };

  return (
    <>
      <section id="deskTopView">
        <div
          className="d-flex justify-content-center align-items-center h-100 flex-column summitbannerContent"
          style={{ marginTop: '20px' }}
        >
          <span className="d-block text-uppercase">Gallery</span>
        </div>

        <div className="slideshow">
          <div
            className="slideshowSlider"
            style={{ transform: `translate3d(${-index * getTranslatePercentage()}%, 0, 0)` }}
          >
            {galleryPhotos.map((image, idx) => (
              <div className="slide" key={idx} onClick={() => openDialog(image)}>
                <div className="container image-container">
                  <img loading="lazy" src={image} alt={`Gallery Image ${idx + 1}`} />
                </div>
              </div>
            ))}
          </div>

          <div className="slideshowDots">
            {galleryPhotos.map((_, idx) => (
              <div
                key={idx}
                className={`slideshowDot${index === idx ? ' active' : ''}`}
                onClick={() => setIndex(idx)}
              ></div>
            ))}
          </div>
        </div>
      </section>
      <section id="mobileView">
        <SummitMSilder24 photoList={galleryPhotos || []} />
      </section>

      {/* Modal/Dialog */}
      {isDialogOpen && (
        <div className="summitSpeakerSlider modal-backdrop" onClick={closeDialog}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={closeDialog}>
              &times;
            </button>
            <img
              src={currentImage}
              alt="Selected"
              className="modal-image"
              loading="lazy"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SummitSlider24;
